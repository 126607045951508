import React from "react"

import { useIntl } from "react-intl"

import { RegisterForm } from "~/components/Forms"
import SEO from "~/components/Seo/Seo"
import UserFormTemplate from "~/components/UserFormTemplate/UserFormTemplate"
import useIsClient from "~/hooks/useIsClient"
import withTemplate from "~/templates/withTemplate"

function RegisterPage() {
  const intl = useIntl()

  const welcomeMessage = [
    intl.formatMessage({ id: "profile/text:welcome-to-the-club" }),
    intl.formatMessage({ id: "profile/text:registration-is-free" }),
  ].join(" ")

  const isClient = useIsClient()

  return isClient ? (
    <>
      <SEO
        title={intl.formatMessage({ id: "profile/text:register" })}
        description={welcomeMessage}
      />

      <UserFormTemplate title={welcomeMessage} Form={<RegisterForm />} />
    </>
  ) : null
}

export default withTemplate(RegisterPage)
